import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import { ThemeProvider } from '@mui/material/styles'
import Wrapper from './components/ Wrapper'
import theme from './theme'

import English from './lang/en.json'
import French from './lang/fr.json'
const locale = navigator.language.split(/[-_]/)[0]
let lang = locale == 'en' ? English : French

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Wrapper>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
    </Wrapper>
  </React.StrictMode>
);

