import React from 'react'

const styles = () => ({
  logoDesk: {    
    display: {xs: 'none', lg: 'flex' },
    flexGrow: 1,
    backgroundColor: 'white',
    maxWidth: '100%',
    padding: 0
  }
})

export default styles
