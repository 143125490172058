import React, { useState, useRef } from 'react'
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel
} from "./file-upload.styles"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import { FormattedMessage } from "react-intl"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000
const KILO_BYTES_PER_BYTE = 1000

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE)

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null)
  const [files, setFiles ] = useState({})

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  }

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files)
    updateFilesCb(filesAsArray)
  }

  const removeFile = (fileName) => {
    delete files[fileName]
    setFiles({ ...files })
    callUpdateFilesCb({ ...files })
  }

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles)
      setFiles(updatedFiles)
      callUpdateFilesCb(updatedFiles)
    }
  }

  return (
    <>
      <FileUploadContainer>
        <InputLabel>{label}</InputLabel>       
        <DragDropText>
          <FormattedMessage id="upload.dragdrop" defaultMessage="Drag and drop" />
        </DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>          
          <span>
            <FormattedMessage id="upload.button" defaultMessage="Upload Your Resume" />
          </span>
        </UploadFileBtn>
        <FormField
          type="file" 
          ref={fileInputField}
          title=""
          onChange={handleNewFileUpload}
          value=""
          {...otherProps}
        />
      </FileUploadContainer>

      <FilePreviewContainer>
        <span>
        <FormattedMessage id="upload.selectedFile" defaultMessage="Selected File" />
        </span>
        <PreviewList>
          {Object.keys(files).map((fileName,index) => {
            let file = files[fileName]
            let isImageFile = file.type.split("/")[0] === 'image'
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}                  
                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <DeleteOutline                       
                        onClick={() => removeFile(fileName)}
                      />
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            )
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  )
}

export default FileUpload