import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import React from "react";
import { FormattedMessage } from "react-intl";

import InstagramIcon from "@mui/icons-material/Instagram";

import { ListItem } from "@mui/material";
import styles from "./styles";

const menu = [
  <FormattedMessage id="footer.home" defaultMessage="home" link=""/>,
  <FormattedMessage id="footer.about" defaultMessage="about" link="aboutus"/>,
  <FormattedMessage id="footer.workwithus" defaultMessage="contact" link="workwithus" />,
];

const contact = ["join.the.3swellness.team@gmail.com"];

const socials = [
  { title: "instagram", img: <InstagramIcon sx={styles.icon} /> },
];

const Footer = () => {
  return (
    <Box sx={styles.footer}>
      <Grid
        container
        columns={{ sx: 4, sm: 8, md: 12 }}
        sx={styles.gridWrapper}
      >
        <Grid item xs={6} sm={4} md={4}>
          <Box>
            <Typography variant="h3" sx={styles.linkTitle}>
              <FormattedMessage id="footer.menu" defaultMessage="menu" />
            </Typography>
            <List>
              {menu.map((item, index) => {
                return (
                  <ListItem sx={styles.itemList} key={index}>
                    <Link
                      sx={styles.link}
                      underline="none"
                      href={`/${item.props.link}`}
                    >
                      {item}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Box>
            <Typography variant="h3" sx={styles.linkTitle}>
              <FormattedMessage id="footer.contact" defaultMessage="Contact" />
            </Typography>
            <List>
              {contact.map((item, index) => {
                console.log(item)
                return (
                  <ListItem sx={styles.itemList} key={index}>
                    <Link
                      sx={styles.linkContact}
                      underline="none"
                      href={`mailto:${item}`}
                    >
                      {item}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Box>
            <Typography variant="h3" sx={styles.linkTitle}>
              <FormattedMessage id="footer.socials" defaultMessage="Socials" />
            </Typography>
            <List>
              {socials.map((item, index) => {
                return (
                  <ListItem sx={styles.itemList} key={index}>                    
                    <Link href="https://www.instagram.com/3s.wellness" sx={{ marginLeft: "5px" }}>                      
                      {typeof item.img === "string" ? (
                        <Box component="img" src={item.img} />
                      ) : (
                        item.img
                      )}
                      <Box sx={{ marginLeft: "32px", marginTop: "-30px" }}>{item.title}</Box>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid>
      </Grid>
      <Typography display="block" variant="h9" align="center">
        <FormattedMessage
          id="footer.copyright"
          defaultMessage="© 2035 by 3S Wellness"
        />
      </Typography>
    </Box>
  );
};

export default Footer;
