const styles = {
  container: {
    marginTop: 4,
    marginBottom: 4,
    
  },
  paper: {
    padding: 2,
    display: 'flex',
    height:240,
    fontSize: '18px',
    p: '12px 0',
    mb: 3,
  },
  
  divider: {
    mt: 10
  },
  text: {
    color: 'white'
  },
  text_community: {
    color: 'white',
    pr: 2 
  },
  hp_box1: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  hp_box2: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'primary.main', 
    width: '1200px'        
  },
  hp_box3: {
    display: 'flex', 
    justifyContent: 'center',
  },
  hp_cardmedia1: {
    width: '1200px',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',                
    objectFit: "cover",
  },
  hp_cardmedia2:{
    width: '400px',
    height: '353px',
    display: 'flex',
    justifyContent: 'center',                
    objectFit: "cover",
  },
  hp_cardmedia3: {    
    mt: 5,    
    width: '850px',
    height: '385px',
    display: 'flex',
    justifyContent: 'center',                
    objectFit: "cover",    
  },
  hp_paper1: {
    p:2, 
    display:'flex', 
    flexDirection: 'column', 
    height: 320,
  },
  hp_paper2: {
    mt: 3,
    p:2, 
    display:'flex', 
    flexDirection: 'column', 
    height:500
  },
  hp_typography1: {
    color: 'primary.main', 
    display: 'flex', 
    justifyContent: 'center',
  },
  hp_typography2: {
    display: 'flex', 
    justifyContent: 'center',
  },
  hp_button1: {
    mt: 4, 
    color: 'primary.main',
  },
  ap_cardmedia: {
    objectFit: "contain",
    mt: 4  
  }  
}


export default styles
