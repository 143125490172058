import React, {useContext} from 'react'
import { Context } from "../components/ Wrapper"
import {FormattedMessage} from 'react-intl'
import {
  Grid,
  Button,
  Typography,
  Paper,
  Container,
  Box,
  Divider,
  CardMedia,
  IconButton,
} from '@mui/material'
import styles from './styles'
import Support from '@mui/icons-material/Support'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import Healing from '@mui/icons-material/Healing'
import People from '@mui/icons-material/People'
import canadian_flag from '../assets/img/CanadianFlag.webp'
import quebec_flag from '../assets/img/QuebecFlag.webp'
import nunavit_flag from '../assets/img/NunavitFlag.webp'
import red_black_art from '../assets/img/redBlackArt.jpeg'
import drumstick from '../assets/img/drumstik.jpeg'
import roundVector from'../assets/img/roundVectorTotem.jpeg'

import WorkWithUs from '../components/HomeWorkWithUs/WorkWithUs'
const HomePage = () => {
  const context = useContext(Context)
  return (
    <Box
    component='main'
    sx={styles.hp_box1}
  >
   <Container maxWidth='lg' sx={styles.container}>
        <workwithus /> 
        <Grid item xs={12} sx={{position: 'relative'}}>              
          <Box sx={{position: 'absolute', left: '-130px', display: 'flex', alignItems: 'center',  flexDirection: 'column' }} >
               <CardMedia 
                  sx={{ height: 85, width: 120, mb: 15 }} //className={classes.pageMedia} 
                  component='img'
                  object-fit='cover'  
                  image={canadian_flag}
               />
               <CardMedia
                  sx={{ height: 85, width: 120, mb: 21.5 }} //className={classes.pageMedia}
                  component='img'
                  object-fit='cover'  
                  image={quebec_flag}
               />     
               <CardMedia
                  sx={{ height: 85, width: 120, mb: 20 }} //className={classes.pageMedia}
                  component='img'
                  object-fit='cover'  
                  image={nunavit_flag}
               />     
          </Box> 
          <Box sx={{position: 'absolute', right: '-120px', display: 'flex', flexDirection: 'column' }} >
               <CardMedia 
                  sx={{ height: 105, width: 105, mb: 10 }} //className={classes.pageMedia} 
                  component='img'
                  object-fit='cover'  
                  image={red_black_art}
               />
               <CardMedia
                  sx={{ height: 142, width: 105, mb: 14.5 }} //className={classes.pageMedia}
                  component='img'
                  object-fit='cover'  
                  image={roundVector}
               />     
               <CardMedia
                  sx={{ height: 107, width: 110 }} //className={classes.pageMedia}
                  component='img'
                  object-fit='cover'  
                  image={drumstick}
               />     
          </Box> 

          <CardMedia
            component="img"
            image='hands.jpeg'
            onLoad={() => console.log("this is loading")}
            onError={() => console.log("this is error")}
            alt="This is a sample image"
            sx={styles.hp_cardmedia1}
          />

          <Box sx={styles.hp_box2}>
            <Typography sx={styles.text}>
              <IconButton><Support /></IconButton>
              <FormattedMessage id="app.support" defaultMessage="Support"/>
            </Typography>
            <Typography sx={styles.text}>
              <IconButton><ArrowUpward /></IconButton>
              <FormattedMessage id="app.growth" defaultMessage="Growth"/>
            </Typography>  
            <Typography sx={styles.text}>
              <IconButton><Healing /></IconButton>
              <FormattedMessage id="app.healing" defaultMessage="Healing"/>
            </Typography>
            <Typography sx={styles.text_community}>
              <IconButton><People /></IconButton>
              <FormattedMessage id="app.community" defaultMessage="Community"/>
            </Typography>
          </Box>
          <Divider sx={styles.divider}/>
        </Grid>
         
          <Grid container >
            <Grid item xs={12} md={8} lg={8}>
              <Paper elevation={3} sx={styles.hp_paper1} >
                <Typography sx={styles.hp_typography1} variant='h3'noWrap gutterBottom>
                    <FormattedMessage id="about.usTitle" defaultMessage="About Us"/>
                </Typography>
                  <Typography display="block" variant="body1">
                      <FormattedMessage id="about.us" defaultMessage="About Us"/>
                </Typography>                
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>             
              <CardMedia
                component="img"
                image='dawid-zawila-lH4kUMxY6Y8-unsplash.jpg'
                onLoad={() => console.log("this is loading")}
                onError={() => console.log("this is error")}
                alt="This is a sample image"
                sx={styles.hp_cardmedia2}
              />              
            </Grid>
        </Grid>

        <Grid container >            
            <Grid item xs={12} md={8} lg={8} > 
            <Paper elevation={3} sx={styles.hp_paper2} >         
              <CardMedia
                component="img"
                image='https://static.wixstatic.com/media/43c14d_73c50afbf81c4032bec81e3c0dbda6e9~mv2.jpg'
                onLoad={() => console.log("this is loading")}
                onError={() => console.log("this is error")}
                alt="This is a sample image"
                sx={styles.hp_cardmedia3}
              />  
            </Paper>            
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
              <Paper elevation={3} sx={styles.hp_paper2} >
                <Typography sx={styles.hp_typography1} variant='h3'noWrap gutterBottom>
                      <FormattedMessage id="app.community" defaultMessage="Community"/>
                </Typography>
                <Typography  display="block" sx={styles.hp_typography2} variant='body1' gutterBottom>
                      <FormattedMessage id="app.communityLit" defaultMessage="Community"/>
                </Typography>
              </Paper>              
          </Grid>         
        </Grid>     
        <WorkWithUs />        
   </Container>
   
   </Box>
  )
}

export default HomePage
