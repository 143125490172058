const styles = {
  btnLink: (theme) => ({
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'capitalize',
    display: 'flex',
    padding: '0',
    marginRight: '44px',
    color: '#000000',
    marginBottom: '10px',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
    color: '#686868'
    },
  }),
  navbar: {
    flexGrow: 1,
    display: { lg: 'flex', xl: 'flex' } , 
    justifyContent: 'flex-end',
    marginRight: '100px', 
  },  
  menuItem: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    fontSize: '18px',           
    color: 'black',
    textTransform: 'capitalize',
    background: '#FF700A',
    marginRight: '80px',
  },  
}

export default styles