import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Header from "../components/Header"
import HomePage from '../pages/HomePage'
import AboutPage from '../pages/AboutPage'
import WorkWithUsPage from '../pages/WorkWithUsPage'
import AdminLoginPage from '../pages/AdminLoginPage'
import AdminMenu from '../pages/AdminMenu'
import PrivateRoutes from './PrivateRoutes'
import UserContext from "./AccountContext"

import Footer from "../components/Footer/Footer"

const App = () => {
  return (
    <BrowserRouter>  
      <UserContext>
        <Header />
        <Routes>
          <Route path='/' element={<HomePage />} /> 
          <Route path='/workwithus' element={<WorkWithUsPage />} /> 
          <Route path='/aboutus' element={<AboutPage />} /> 
          <Route path='/admin' element={<AdminLoginPage />} /> 
          <Route element={<PrivateRoutes />}>
             <Route path='/adminmenu' element={<AdminMenu />} /> 
          </Route>  
        </Routes> 
        <Footer/>
      </UserContext>
   </BrowserRouter>   
 )
}
export default App
