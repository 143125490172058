import React from 'react'
import { Typography, Box, Grid, Paper, CardMedia }  from '@mui/material'
import { FormattedMessage } from 'react-intl'
import AboutUsFormComponent from "../components/AboutUsForm/AboutUsFormComponent";
import styles from './styles'

const AboutPage = () => {
  return (
    <Box>
      <Box mt={5}>
        <Typography fontFamily='Roboto' variant='h2' align='center' color='primary.main'>
          <FormattedMessage id="about.title" defaultMessage="About 3S Wellness"/>
        </Typography>
      </Box>
      <Box mx='25%' width='50%' mt={3}>
        <Typography variant='p'sx={{fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
          <FormattedMessage id="about.para" defaultMessage="At 3S Wellness, our..."/>
        </Typography>
      </Box>
      <Grid container mx='25%' >
        <Grid item xs={6} >
          <CardMedia
              component="img"
              image='Stone-Pile.jpeg'
              onLoad={() => console.log("this is loading")}
              onError={() => console.log("this is error")}
              alt="This is a sample image"
              sx={styles.ap_cardmedia}            
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AboutPage
