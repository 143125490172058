import { Grid, Typography, Divider } from "@mui/material";
import React from "react";

import { FormattedMessage } from "react-intl";

const WorkWithUs = () => {
  return (
    <Grid container sx={{ display: 'flex', justifyContent: "center" }}>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant="h2" sx={{ color: "primary.main", mt: 10 }}>
          <FormattedMessage id="app.workwithusTitle" defaultMessage="Work with Us"/>
        </Typography>

        <Typography display="block" variant="h6" sx={{ color: "black", mb:5 }}>
          <FormattedMessage
            id="app.workwithusSubTitle"
            defaultMessage="Are you willing?"
          />
        </Typography>
      </Grid>

      <Grid item xs={3} sx={{ textAlign: "center", color: "primary.main", mt: 5, fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
        <Typography display="block" variant="h7">
          <FormattedMessage
            id="app.fullfillingWork"
            defaultMessage="Fulfilling Work"
          />
        </Typography>
        <Typography display="block" variant="p" align='left' sx={{color:'#686868', mt: 2, pr: '2%'}}>
          <FormattedMessage id="app.fullWorkPara" defaultMessage="As part of..." />
        </Typography>
      </Grid>

      <Divider orientation="vertical" variant="middle" flexItem color= "#FF700A"/>

      <Grid item xs={4} sx={{ textAlign: "center", color: "primary.main", mt: 5, fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
        <Typography display="block" variant="h7">
          <FormattedMessage id="app.growthOpp" defaultMessage="Growth Opportunity" />
        </Typography>
        <Typography display="block" variant="p" align='left' sx={{color:'#686868', mt: 2, pl: '7%', pr: '7%'}}>
          <FormattedMessage id="app.growthOppPara" defaultMessage="We are committed..." />
        </Typography>
      </Grid>

      <Divider orientation="vertical" variant="middle" flexItem color= "#FF700A"/>

      <Grid item xs={4} sx={{ textAlign: "center", color: "primary.main", mt: 5, pl: '2%', fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
        <Typography display="block" variant="h7">
          <FormattedMessage id="app.coll" defaultMessage="Collaborative Environment"/>
        </Typography>
        <Typography display="block" variant="p" align='left' sx={{color:'#686868', mt: 2 }}>
          <FormattedMessage id="app.collabEnvPara" defaultMessage="We foster a..." />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WorkWithUs;
