import { useContext } from 'react'
import  { Outlet, Navigate } from "react-router"
import { AccountContext } from './AccountContext'

const useAuth = () => {
    const { user } = useContext(AccountContext)
    return user && user.loggedIn
}

const PrivateRoutes = () => {
    const isAuth = useAuth()
    return isAuth ?  <Outlet /> : <Navigate to="/admin" />
}

export default PrivateRoutes
