import MuiButton from "@mui/material/Button";
import React from "react";

const styles = {
  btn: (theme) => ({
    fontFamily: "Monsterrat, sans-serif",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    padding: "20px 60px",
    background: theme.palette.secondary.main,
    borderRadius: "8px",
    "&:padding": {
      background: "#46ab0",
    },
  }),
};

const Button = ({ children, type = "button" }) => {
  return (
    <MuiButton type={type} sx={styles.btn}>
      {children}
    </MuiButton>
  );
};

export default Button;
