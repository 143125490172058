import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import FormComponent from "./FormComponent"
import { injectIntl } from 'react-intl';

import styles from "./styles";

const WorkWithUs = (props) => {
  const { intl } = props
  return (
    <Box sx={styles.contactUs}>
        <FormComponent />
    </Box>
  );
};

export default injectIntl(WorkWithUs);
