import { createTheme } from "@mui/material/styles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF700A",
    },
    secondary: {
      main: "#32CD32",
    },
  },
  typography: {
    h2: {
      fontFamily: "Roboto, sans-serif",
      textTransform: "capitalize",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "39px",
      color: "#000000",
      [breakpoints.up("md")]: {
        fontSize: "64px",
        lineHeight: "78px",
      },
    },
    h3: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "29px",
      textTransform: "capitalize",
      color: "#000000",
    },
    body1: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#686868",
      [breakpoints.up("md")]: {
        fontSize: "24px",
        lineHeight: "29px",
      },
    },
    selectStyle: {
      marginTop: 0,
      marginBottom: '20px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '18px',           
      color: 'black',    
      textTransform: 'capitalize', 
      [breakpoints.up("lg")]: {
        marginTop: "-23px",
        marginBottom: 0
      },   
    },
    someStyle: {
      fontFamily: "Roboto, sans-serif",
      textTransform: "capitalize",
      
    },
    overrides: {
      MuiMenuItem: {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "29px",
        lineHeight: "22px",
        color: "black",
        textTransform: "capitalize",
      },
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
        pr: '24px',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "0.5px solid #777FEB",
        },
        root: {
          borderRadius: "8px",
          color: "#000",
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 500,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #777FEB",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #777FEB",
          },
        },
      },
    },
  },
});

export default theme;
