import React from 'react'
import { NavLink } from "react-router-dom"
import  Link  from '@mui/material/Link'
import DropDown from './DropDown'

import styles from './styles'

const BtnNav = ({ page }) => {
  return (
    <>
      {page.arrow ? (
        <DropDown page={page} />
      ): ( 
        <Link 
          component={NavLink}  
          to={page.link} 
          sx={styles.btnLink} 
        style={({ isActive }) => isActive ? {color:'white'} : {color: 'black'} } 
        >
          {page.title}
        </Link>
      )} 
    </>
  )
}

export default BtnNav
