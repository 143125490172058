import React, {useContext} from 'react'
import { Context } from "../ Wrapper"
import { Box, Select, MenuItem } from '@mui/material'
import BtnNav from './BtnNav'
import theme from '../../theme'
import { FormattedMessage } from 'react-intl'

import styles from './styles'

 const pages = [
  {title: <FormattedMessage id="navbar.home" defaultMessage="Home" />, arrow: false, link: '/' },
  {title: <FormattedMessage id="navbar.about" defaultMessage="About" />, arrow: false, link: '/aboutus' },
  {title: <FormattedMessage id="navbar.workwithus" defaultMessage="Work With Us" />, arrow: false, link: '/workwithus' },
  {title: <FormattedMessage id="navbar.admin" defaultMessage="Admin" />, arrow: false, link: '/admin' },
]; 

const Navbar = () => {
  const context = useContext(Context)

  return (
    <Box sx={styles.navbar}>
    {pages.map((page,index) => (
       <BtnNav page={page} key={index} />
    ))}
    <Select
      id='basic-menu'
      value= {context.locale}
      onChange={context.selectLanguage} 
      sx={theme.typography.selectStyle}
    >
      <MenuItem 
        value='en'
        style={styles.menuItem}
        >
        English
      </MenuItem>
      <MenuItem 
        value='fr'
        style={styles.menuItem}
    >
      French
    </MenuItem>
    </Select>
  </Box>
  )
}

export default Navbar
