import React, { useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router'
import { Box, Button, TextField, Typography } from '@mui/material'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import axios from 'axios'
import { AccountContext } from "../components/AccountContext"


const AdminLoginPage = () => {
  const { setUser } = useContext(AccountContext)  
  const navigate = useNavigate()  
  const [formData, setFormData] = useState({
      username:"",
      password: ""
  })

  const handleChange = (e) => {
      setFormData( (prevState) => ({
          ...prevState,
          [e.target.name] : e.target.value
      }))
  }

  const handleRegistration = (e) => {
    e.preventDefault()
    axios.post('http://localhost:4000/auth/signup', formData)
        .then(res => {
            if( res.data.loggedIn ) { 
                navigate('/admin');
             } else {
                 alert(res.data.status)
             }   
        })  
        .catch( err => console.log(err));
   }  

  const handleSubmit = (e) => {
      e.preventDefault()

      axios.post('http://localhost:4000/auth/login', formData)
          .then(res => {
              if( res.data.loggedIn ) {
                  setUser({...res.data})
                  navigate('/adminmenu');
               } else {
                   alert(res.data.errors[0].msg)
               }
          })
          .catch( err => console.log(err));

      setFormData({ email:"", password: "" })
  }

  return (
    <div>
      <form onSubmit={handleSubmit} >
        <Box
            display="flex"
            flexDirection={"column"}
            maxWidth={400}
            alignItems="center"
            justifyContent='center'
            margin='auto'
            marginTop={5}
            padding={5}
            borderRadius={5}
            boxShadow={'5px 5px 10px #ccc'}
            sx={{
                ":hover": {
                    boxShadow: '10px 10px 20px #ccc',
                }
            }}
        >
            <Typography fontFamily='Roboto' variant='h2' padding={3} textAlign="center" color='primary.main'>Login</Typography>
            <TextField
                name="username"
                onChange={handleChange}
                value={formData.email}
                margin='normal'
                type={'email'}
                variant="outlined"
                placeholder="Email"
            />
            <TextField
                name="password"
                onChange={handleChange}
                value={formData.password}
                margin='normal'
                type={'password'}
                variant="outlined"
                placeholder="Password"
            />
            <Button
                endIcon={<LoginOutlinedIcon />}
                type="submit"
                sx={{ marginTop: 3, borderRadius: 3 }}
                variant='contained'
                color='warning'
            >
                <FormattedMessage id="admin.login" defaultMessage="Login"/>
            </Button>
        </Box>
     </form>
    </div>
  )
}

export default AdminLoginPage
