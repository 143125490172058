import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import Navbar from '../../components/Navbar'

import close from '../../assets/img/close.svg';
import { IconButton, Box } from '@mui/material';

const Drawer = ({open, onClose}) => {
  return (
    <MuiDrawer 
        PaperProps={{
            sx: { width: '100%', zIndex: 4 },
        }}
        anchor='left'
        open={open}
        onClose={onClose}
    >
      <Box sx={{padding: '2px' }}>
        <IconButton 
          onClick={onClose}
          sx={{padding: 0, marginLeft: '5px' }} >
            <img src={close}  alt='close' />
        </IconButton>
        <Box sx={{ padding: '44px' }}>
            <Navbar />
        </Box>
      </Box>
    </MuiDrawer>
  )
}

export default Drawer