import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Box from "@mui/material/Box"
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize"
import axios from "axios"
import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import FileUpload from "../file-upload/file-upload.component"
import Button from "../Button"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

import { useFormik } from "formik";
import * as yup from "yup";

import styles from "./styles";

const validationSchema = yup.object({
  email: yup
    .string()
    .required(
      <FormattedMessage
        id="contactUsYup.emailRequired"
        defaultMessage="Email is required"
      />
    ),
  firstName: yup
    .string()
    .required(
      <FormattedMessage
        id="contactUsYup.firstNameRequired"
        defaultMessage="First name is required"
      />
    ),
  lastName: yup
    .string()
    .required(
      <FormattedMessage
        id="contactUsYup.lastNameRequired"
        defaultMessage="Last name is required"
      />
    ),
  phone: yup
    .string()
    .required(
      <FormattedMessage
        id="contactUsYup.phoneRequired"
        defaultMessage="Phone number is required"
      />
    ),
});

const FormComponent = (props) => {
  const [open, setOpen] = React.useState(false)
  const [posn, setPosn] = React.useState('PSW')
  const setStatus = () => setOpen(true);
  const { intl } = props;

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const handleSelectChange = (event) => {
    setPosn(event.target.value);
  };

  const updateUploadedFiles = (files) => {
    console.log(files)
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const firstname = values.firstName;
      const lastname = values.lastName;
      const email = values.email;
      const phonenum = values.phone;
      const message = values.message;

      axios
        .post("http://localhost:3005/workwithus", { firstname, lastname, email, phonenum, message })
        .then((response) => {
          setStatus();
          console.log(snackStatus);
          resetForm();
        })
        .catch((error) => {
          console.log(error);
          setStatus(error.message || error.statusText);
        });
    },
  });
  return (    
    <form onSubmit={formik.handleSubmit}>
      <Box sx={styles.input}>
        <TextField
          id="firstName"
          name="firstName"
          required
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          placeholder={intl.formatMessage({
            id: "contactUs.firstNamePlaceholder",
            defaultMessage: "First Name",
          })}
          sx={styles.textField}
        />
        <TextField
          id="lastName"
          name="lastName"
          required
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          placeholder={intl.formatMessage({
            id: "contactUs.lastNamePlaceholder",
            defaultMessage: "Last Name",
          })}
          sx={styles.textField}
        />

        <TextField
          id="email"
          name="email"
          required
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          placeholder={intl.formatMessage({
            id: "contactUs.emailPlaceholder",
            defaultMessage: "Email",
          })}
          sx={styles.textField}
        />
        <TextField
          id="phone"
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          placeholder={intl.formatMessage({
            id: "contactUs.phonePlaceholder",
            defaultMessage: "Phone No.",
          })}
          sx={styles.textField}
        />
        <Box sx={{  mt: '70px',mb: '30px', color: 'gray', width: '100%', display: 'flex', justifyContent: 'space-between', textAlign: 'left', fontFamily: 'Roboto', fontSize: '20px', fontWeight: '100'}}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs} >  
              <label>
                {intl.formatMessage({ id: "careervolunteer.startDate", defaultMessage: "Start Date" })}
              </label>
              <DatePicker  sx={{ ml: '15px', mt: '-24px', mb: '20px'}}/>
            </LocalizationProvider> 
          </Box>
          <Box display='flex' >
            <InputLabel id="position" sx={{ mr: '20px', color: 'gray', fontFamily: 'Roboto', fontSize: '20px', fontWeight: '100'}}>
               {intl.formatMessage({ id: "careervolunteer.position", defaultMessage: "Position" })}
            </InputLabel>
            <Select
              labelId="position"
              id="position-select"
              value={posn}
              label="Position"
              onChange={handleSelectChange}    
              sx={{ mt: '-30px', fontFamily: 'Roboto', fontSize: '20px', fontWeight: '100', color: 'gray'}}          
            >
              <MenuItem value='PSW'>PSW</MenuItem>
              <MenuItem value='VOL'>Volunteer</MenuItem>
            </Select>   
          </Box>          
        </Box>
       </Box>
       <Box sx={{ mt: '30px', color: 'gray', fontFamily: 'Roboto', fontSize: '20px', fontWeight: '100'}}>
        <label>
          {intl.formatMessage({ id: "upload.label", defaultMessage: "Last Name" })}
        </label> 
        <FileUpload 
            accept=".pdf"  
            updateFilesCb={updateUploadedFiles}
          />
        
       </Box>
      <TextareaAutosize
        minRows={6}
        id="message"
        name="message"
        value={formik.values.message}
        onChange={formik.handleChange}
        error={formik.touched.message && Boolean(formik.errors.message)}
        helperText={formik.touched.message && formik.errors.message}
        sx={styles.textField}
        placeholder={intl.formatMessage({
          id: "contactUs.messagePlaceholder",
          defaultMessage: "Message",
        })}
        style={styles.textareaAutosize}
      />
      <Button
        type={"submit"}  
        sx= {{ 
          "&:hover": {
            backgroundColor: '#12a987 !important', // <- add here your desired color, for demonstration purposes I chose red
          }
        }}        
      >
        {intl.formatMessage({
          id: "contactUs.submitPlaceholder",
          defaultMessage: "Submit",
        })}
        <ArrowForwardIosIcon />
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        ContentProps={styles.contentProps}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={intl.formatMessage({ id: "submit.success", defaultMessage: "Submit" })}
      />
    </form>     
   
  );
};

export default injectIntl(FormComponent);
