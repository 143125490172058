import React from 'react'
import WorkWithUs from "../components/WorkWithUs"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import {FormattedMessage} from 'react-intl'

const WorkWithUsPage = () => {
  return (
    <Box>
      <Box mx='20%' width='70%' mt={3}>
        <Typography fontFamily='Roboto' variant='h2' gutterBottom='true' align='center' color='primary.main'>
            <FormattedMessage id="careerorvolunteer.title" defaultMessage="Career or Volunteer"/>
          </Typography>
          <Typography fontFamily='Roboto' display="block" variant='p' align='left' sx={{fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
            <FormattedMessage id="careerorvolunteer.message1" defaultMessage="Career message"/>
          </Typography>          
          <br/>          
          <Typography fontFamily='Roboto' display="block" variant='p' align='left' sx={{fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
            <FormattedMessage id="careerorvolunteer.message2" defaultMessage="Career message"/>
          </Typography>
          <br/> 
          <Typography fontFamily='Roboto' display="block" variant='p' align='left' sx={{fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
            <FormattedMessage id="careerorvolunteer.message3" defaultMessage="Career message"/>
          </Typography>
          <br/>
          <Typography fontFamily='Roboto' display="block" variant='p' align='left' sx={{fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
            <FormattedMessage id="careerorvolunteer.message4" defaultMessage="Career message"/>
          </Typography>
          <br/>
          <Typography fontFamily='Roboto' display="block" variant='p' align='left' sx={{fontFamily: 'Roboto', fontSize: '24px', fontWeight: '400' }}>
            <FormattedMessage id="careerorvolunteer.message5" defaultMessage="Career message"/>
          </Typography>
          <WorkWithUs />
      </Box>
    </Box>
  )
}

export default WorkWithUsPage
