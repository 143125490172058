import React from 'react'
import {
  Typography,
  Container,
  Box  
} from '@mui/material'
import styles from './styles'

const AdminMenu = () => {
  return (
    <div>
        Welcome Administrators
    </div>
  )
}

export default AdminMenu
