import { createContext, useState, useEffect } from 'react'

export const AccountContext = createContext()

const UserContext =({children}) => {
    const [user, setUser] = useState({ loggedIn: null })

    useEffect(() => {
      fetch("http://localhost:4000", {
          credentials: "include",
      })
      .catch(err => {
          setUser({ loggedIn: false })
          return
      })
      .then(resp => {
          if (!resp || !resp.ok || resp.status >= 400) {
              setUser({ loggedIn: false })
              return
          }
      })
      .then(
          data => {
              if (data) {
                  setUser({ ...data })
              }
          }
      )
    }, [])

    return (
        <AccountContext.Provider value={{user, setUser}}>
            {children}
        </AccountContext.Provider>
    )
}

export default UserContext
