import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import CardMedia from '@mui/material/CardMedia'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import Container from '@mui/material/Container'

import Navbar from '../Navbar'
import Drawer  from '../Drawer'
import logo_desk from '../../assets/img//3sw_logo.png'
import logo_mob from '../../assets/img/3sw_logo.png'
import burger_menu from '../../assets/img/burger_menu.svg'

import styles from './styles'
import { Padding } from '@mui/icons-material'

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false) 

  return (
    <AppBar position='static' sx={{padding: 0}}>
    <Container maxWidth='false'  sx={styles.container}>
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} />
        <Toolbar disableGutters>
            <Typography component='a' href="/" sx={{ backgroundColor: 'white' }}>
              <img src={logo_desk}  alt="Logo" />
            </Typography>
            <Box sx={{flexGrow: 1, display: {xs: 'flex', lg: 'none'}}}>
               <IconButton size='large' aria-label='menu' onClick={()=> setOpenDrawer(true)}>
                  <img src={burger_menu} alt='burger menu' />
               </IconButton>
            </Box>
            {/*  <Typography component='a' href="/" sx={styles.logoMob}>
              <img src={logo_mob} alt="Logo" />
            </Typography> */}
            <Box sx={{flexGrow: 1, 
                  display: {xs: 'none', md: 'flex', lg: 'flex', xl: 'flex'}}}>
               <Navbar />
            </Box>
        </Toolbar>
    </Container>
    </AppBar>
  )
}

export default Header

