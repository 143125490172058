const styles = {
  footer: (theme) => ({
    maxWidth: "1240px",
    padding: "44px 20px",
    textAlign: "left",
    margin: "auto",
  }),
  gridWrapper: (theme) => ({
    padding: "44px 0px",
    borderTop: `0.5px solid theme.palette.secondary`,
    borderBottom: `0.5px solid theme.palette.secondary`,
    rowGap: "24px",
    textAlign: "left",
    margin: "auto",
  }),
  linkTitle: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "left",
  },
  itemList: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    fontSize: "18px",
    p: "12px 0",
    color: "#686868",
    lineHeight: "20px",
    textTransform: "capitalize",
  },
  link: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    fontSize: "18px",
    p: "12px 0",
    color: "inherit",
    lineHeight: "3px",    
    underline: "none",
  },
  linkContact: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    fontSize: "18px",
    p: "12px 0",
    color: "inherit",
    textTransform: "lowercase",
    lineHeight: "3px",    
    underline: "none",
  },
};

export default styles;
