import React, { useContext } from 'react'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import arrow from '../../assets/img/chevron-down.svg'
import styles from './styles'
import { Context } from "../ Wrapper"

export default function BasicMenu({ page }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const context = React.useContext(Context)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }  

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        disableRipple
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={styles.btnLink}
      >
        {page.title} <img src={arrow} alt='arrow' />
      </Button>      
    </div>
  )
}
